const items = [
  {
    name: "business",
    title: "Business",
    iconName: "business_center",
    icon: "BriefcaseIcon",
    default: true,
    subItems: [
      {
        name: "details",
        title: "Details",
        route: "/settings/details",
        type: "businessDetails",
        default: true,
      },
      {
        name: "billing",
        title: "Billing",
        route: "/settings/billing",
        type: "businessBilling",
      },
      {
        name: "directBilling",
        title: "Direct Billing",
        route: "/settings/directBilling",
        type: "businessDirectBilling",
      },
      {
        name: "locations",
        title: "Locations",
        route: "/settings/locations",
        type: "businessLocations",
      },
      {
        name: "staff",
        title: "Staff",
        route: "/settings/staff",
        type: "businessStaff",
      },
      {
        name: "roster",
        title: "Roster",
        route: "/settings/rosters",
        type: "businessRosters",
      },
      {
        name: "clients",
        title: "Clients",
        route: "/settings/clients",
        type: "businessClients",
      },
      {
        name: "calendar",
        title: "Calendar",
        route: "/settings/calendar",
        type: "businessCalendar",
      },
      {
        name: "onlineBooking",
        title: "Online Booking",
        route: "/settings/online-booking",
        type: "businessOnlineBooking",
      },
      {
        name: "intake",
        title: "Intake / Waiver",
        route: "/settings/intake",
        type: "businessIntakeWaiver",
      },
      {
        name: "charting",
        title: "Charting",
        route: "/settings/charting",
        type: "businessCharting",
        noRouting: true,
      },
      {
        name: "userActivity",
        title: "User Activity",
        route: "/settings/user-activities",
        type: "businessUserActivity",
      },
      {
        name: "addons",
        title: "Apps / Add-ons",
        route: "/settings/addons",
        type: "businessAppsAddOns",
      },
    ],
  },
  {
    name: "sales",
    iconName: "monetization_on",
    icon: "CoinIcon",
    title: "Sales",
    subItems: [
      {
        name: "services",
        title: "Services",
        route: "/settings/sales/services",
        type: "salesServices",
      },
      // {
      //   name: "classes",
      //   title: "Classes",
      //   route: "/settings/sales/classes",
      //   type: "salesClasses",
      //   noRouting: true,
      // },
      {
        name: "products",
        title: "Products",
        route: "/settings/sales/products",
        type: "salesProducts",
      },
      {
        name: "packages",
        title: "Packages",
        route: "/settings/sales/packages",
        type: "salesPackages",
      },
      {
        name: "discounts",
        title: "Discounts",
        route: "/settings/sales/discounts",
        type: "salesDiscounts",
      },
      {
        name: "giftCards",
        title: "Gift Cards",
        route: "/settings/sales/gift-cards",
        type: "salesGiftCards",
      },
      {
        name: "resources",
        title: "Resources",
        route: "/settings/sales/resources",
        type: "salesResources",
      },
      {
        name: "cardOperation",
        title: "Client CC Required",
        route: "/settings/sales/cardOperation",
        type: "salesCardOperation",
        noDisplayed: true
      },
      {
        name: "deleteRequestPayment",
        title: "Delete Online Payment",
        route: "/settings/sales/requestPayment",
        type: "deleteOnlineRequestPayment",
        noDisplayed: true
      },
      {
        name: "changePaymentDate",
        title: "Change payment date",
        route: "/settings/sales/changePaymentDate",
        type: "changePaymentDate",
        noDisplayed: true,
      },
      {
        name: "Settings",
        title: "Sales Settings",
        route: "/settings/sales/settings",
        type: "salesSettings",
      },
    ],
  },
  {
    name: "messaging",
    iconName: "textsms",
    icon: "ChatIcon",
    title: "Messaging",
    subItems: [
      {
        name: "checkInMessaging",
        title: "Check In",
        route: "/settings/messaging/checkIn",
        type: "checkInMessagingSettings",
      },
      {
        name: "googleReview",
        title: "Google Reviews",
        route: "/settings/messaging/reviews",
        type: "googleReviewMessagingSettings",
      },
      {
        name: "recoveryMessaging",
        title: "Recovery",
        route: "/settings/messaging/recovery",
        type: "recoveryMessagingSettings",
      },
      {
        name: "clientsMessaging",
        title: "Clients",
        route: "/settings/messaging/clients",
        type: "clientsMessagingSettings",
        noRouting: true,
        noDisplayed: true
      },
      {
        name: "staffMessaging",
        title: "Staff",
        route: "/settings/messaging/staff",
        type: "staffMessagingSettings",
        noRouting: true,
        noDisplayed: true
      },
      {
        name: "clientBirthday",
        title: "Birthday",
        route: "/settings/messaging/birthday",
        type: "birthdayMessagingSettings",
      },
      {
        name: "buzzStaff",
        title: "Buzz",
        route: "/settings/messaging/buzz",
        type: "buzzStaffMessagingSettings",
      },
      {
        name: "smsSettings",
        title: "Settings",
        route: "/settings/messaging/sms",
        type: "messagingSmsSettings",
      },
    ],
  },
  {
    name: "reports",
    title: "Reports",
    iconName: "announcement",
    icon: "ReportingIcon",
    subItems: [
      {
        name: "analytics",
        title: "Analytics",
        route: "/settings/reports/analytics",
        type: "reportsAnalytics",
      },
      {
        name: "sales",
        title: "Sales",
        route: "/settings/reports/sales",
        type: "reportsSales",
      },
      {
        name: "payments",
        title: "Payments",
        route: "/settings/reports/payments",
        type: "reportsPayments",
      },
      {
        name: "staffKPI",
        title: "Staff KPI",
        route: "/settings/reports/staff-kpi",
        type: "reportsStaffKPI",
        noDisplayed: true,
        noRouting: true
      },
      {
        name: "custom",
        title: "Custom",
        route: "/settings/reports/custom",
        type: "reportsCustom",
        noRouting: true,
      },
    ],
  },
  {
    name: "personalDashboards",
    title: "Personal Dashboard",
    noDisplayed: true,
    subItems: [
      {
        name: "personalDashboard",
        title: "Show all appointments",
        route: "/settings/reports/personalDashboard",
        type: "personalDashboard",
        noDisplayed: true,
      },
    ],
  },
  {
    name: "promote",
    iconName: "announcement",
    icon: "MegaphoneIcon",
    title: "Promote",
    subItems: [
      {
        name: "referral",
        title: "Referral",
        route: "/settings/promote/referral",
        type: "promoteReferral",
        noRouting: true,
      },
    ],
  },
];

export default items;
